import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import dcImage from './images/buttons/discord.png';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import {ColorButton} from "./classes.js";
import {Box} from "@mui/material";


function App() {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [currentPage, setCurrentPage] = useState('Home')


    const SetCurrentPage = async(page) =>{
        if (page !== "Whitepaper"){
            setCurrentPage(page)
            localStorage.setItem('currentPage',page)
        }
        closeNavMenu()
        if (page === "Whitepaper") {
            window.open("https://whitepaper.nuldaeron.com/", "_blank");
        } else {
            if (page === "Home"){
                document.body.className = "fullBg";
                document.getElementById("footer").className = "absoluteBottom";
            }
        }
    }

    const closeNavMenu = () => {
        setAnchorElNav(null);
    }



    const discordClick = () => {
        window.open("https://twitter.com");
        window.dataLayer.push({
            "event": "DiscordClick"
        });
    }

    useEffect(()=>{
        const init = async () => {
            if (window.performance) {
                if (performance.navigation.type === 1) {
                    const page = localStorage.getItem('currentPage')
                    await SetCurrentPage(page)
                } else {
                    await SetCurrentPage('Home')
                }

                window.dataLayer = window.dataLayer || [];
            }
        }
        init()
    },[])

        return (
            <div>
                <div className="overall"/>
                <div className="centered">
                    <h5>An ethereum based MMORPG with unique <span className="red">PVP</span> gameplay</h5>
                </div>

                <div className="centeredDC">
                        <ColorButton size="large"
                                     variant="outlined"
                                     style={{fontFamily: 'Minecraft-Regular'}}
                                     onClick={discordClick}
                                     sx={{width: 450, height:140,borderRadius: '12px'}}
                                     startIcon={
                                         <Avatar
                                             sx={{ width: 100, height: 100, mr:2,boxShadow:10}}
                                             src={dcImage}
                                         />
                                     }
                        >
                            <Typography variant="h5" align="center" sx={{}} style={{color: 'papayawhip',fontFamily: 'Minecraft-Regular', fontSize:40, fontWeight:'bold'}}>
                                STEP INTO NULDAERON!
                            </Typography>
                        </ColorButton>
                </div>

                <div id="footer" className="bottom">
                    <h5>4444 UNIQUE CHAMPIONS</h5>
                    <h6>Copyright ©2022 World of Nuldaeron®</h6>
                </div>
            </div>
        );
}
export default App;