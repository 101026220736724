import {styled} from "@mui/material/styles";
import {alpha} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";


export const ColorButton = styled(Button)(({ theme }) => ({
    borderColor: '#29151b',
    backgroundColor: alpha('#552d44',0.95),
    borderWidth: '2px',
    boxShadow:15,
    '&:hover': {
        backgroundColor: alpha('#552d44',1),
    },
}));

export const ColorButton2 = styled(Button)(({ theme }) => ({
    borderColor: '#29151b',
    backgroundColor: alpha('#552d44',1),
    borderWidth: '2px',
    '&:hover': {
        backgroundColor: alpha('#552d44',0.8),
    },
}));